import classNames from 'classnames';
import { Component } from 'react';
import { animationConfig, createElementDescriptor, initialAnimationStyle, markerStyle } from './utils';
import { motion } from 'framer-motion';

import { MuseumFilterTypes } from '@/enums/MuseumFilterTypes';
import styles from './Exhibition.module.scss';

export default class Exhibition extends Component {
	render() {
		const { x, y, scale, label, link, status, disabled, onMouseEnter, onMouseLeave } = this.props;

		const exhibitionClassNames = classNames(styles.exhibition, {
			[styles.exhibitionCurrent]: status === MuseumFilterTypes.CURRENT,
			[styles.exhibitionPast]: status === MuseumFilterTypes.PAST,
			[styles.exhibitionDisabled]: disabled,
		});

		const element = createElementDescriptor(link);

		return (
			<element.type className={exhibitionClassNames} style={markerStyle(x, y, 1)} {...element.props}>
				<motion.div
					initial={initialAnimationStyle}
					animate={animationConfig}
					transition={{
						duration: 0.5,
					}}
					className={styles.clusterPositioner}
				>
					<div className={styles.exhibitionPositioner}>
						<div className={styles.exhibitionWrapper}>
							<div
								className={styles.exhibitionContent}
								onMouseEnter={() => onMouseEnter(this.props.id)}
								onMouseLeave={() => onMouseLeave(this.props.id)}
							>
								<div className={styles.exhibitionText}>{label}</div>
							</div>
						</div>
					</div>
				</motion.div>
			</element.type>
		);
	}
}
