import { MercatorProjection } from '@/components/features/map/Map.utils';
import { MapContext } from '@/components/features/map/MapContext';
import { getAnimationDelay } from '@/components/features/map/markers/utils';
import { MapFocusTypes } from '@/enums/MuseumFilterTypes';
import { IClusterResource } from '@/interfaces/IClusterResource';
import { Component } from 'react';
import Cluster from './markers/Cluster';

interface IClustersLayerProps {
	onClusterClick: (cluster) => void;
	onClusterMouseEnter: (id: string) => void;
	onClusterMouseLeave: (id: string) => void;
	clusters: Array<IClusterResource>;
	projection: MercatorProjection;
	hoveredElement: string;
}

export default class ClustersLayer extends Component<IClustersLayerProps> {
	constructor(props, context) {
		super(props, context);
	}

	openCluster(cluster) {
		const { onClusterClick } = this.props;

		if (onClusterClick) {
			onClusterClick(cluster);
		}
	}

	focusElement(elementId) {
		const { onClusterMouseEnter } = this.props;

		if (onClusterMouseEnter) {
			onClusterMouseEnter(elementId);
		}
	}

	unfocusElement(elementId) {
		const { onClusterMouseLeave } = this.props;

		if (onClusterMouseLeave) {
			onClusterMouseLeave(elementId);
		}
	}

	render() {
		const { clusters, projection, hoveredElement } = this.props;

		return (
			<MapContext.Consumer>
				{({ activeItem }) => {
					return clusters.map((data, i) => {
						const cluster = data;
						const { posX, posY } = projection.geoToPixel(cluster.latitude, cluster.longitude);

						const disabled = hoveredElement && hoveredElement !== cluster.id;

						const clusterDisabled = activeItem && activeItem !== MapFocusTypes.CLUSTERS;

						return (
							<Cluster
								key={cluster.id}
								id={cluster.id}
								x={posX}
								y={posY}
								animationDelay={getAnimationDelay(i, clusters.length)}
								count={cluster.objectCount}
								clickHandler={this.openCluster.bind(this, cluster)}
								onMouseEnter={this.focusElement.bind(this)}
								onMouseLeave={this.unfocusElement.bind(this)}
								disabled={disabled || clusterDisabled}
							/>
						);
					});
				}}
			</MapContext.Consumer>
		);
	}
}
