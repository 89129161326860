import { MapFocusTypes } from '@/enums/MuseumFilterTypes';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

interface MapContextInterface {
	activeItem: MapFocusTypes | null;
	setActiveItem: Dispatch<SetStateAction<MapFocusTypes>>;
}

export const MapContext = createContext<MapContextInterface | null>(null);

interface IMapProviderProps {
	children: ReactNode;
}

export const MapProvider: FC<IMapProviderProps> = ({ children }) => {
	const [activeItem, setActiveItem] = useState<MapFocusTypes>(null);

	return <MapContext.Provider value={{ activeItem, setActiveItem }}>{children}</MapContext.Provider>;
};

export const useMapContext = () => {
	const mapContext = useContext(MapContext);

	if (!mapContext) {
		throw new Error('useMapContext must be used within a MapProvider');
	}

	return mapContext;
};
