export enum MuseumFilterTypes {
	ALL = 'all',
	CURRENT = 'current',
	UPCOMING = 'upcoming',
	PAST = 'past',
	OPEN_FOR_DONATION = 'open_for_donation',
	EXHIBITION = 'exibitions',
}

export enum MapFocusTypes {
	MUSEUMS = 'museums',
	EXHIBITS = 'exhibits',
	CLUSTERS = 'clusters',
}
