import { Component, Fragment } from 'react';
import Exhibition from './markers/Exhibition';
import Museum from './markers/Museum';

import { MapContext } from '@/components/features/map/MapContext';
import { MapFocusTypes } from '@/enums/MuseumFilterTypes';
import { IMuseumResource } from '@/interfaces/IMuseumResource';
import { MercatorProjection, MuseumCategoryTypes } from './Map.utils';

interface IMuseumLayerProps {
	museums: Array<IMuseumResource>;
	onMuseumMouseEnter: (id: string) => void;
	onMuseumMouseLeave: (id: string) => void;
	projection: MercatorProjection;
	hoveredElement: string;
	hiddenCategories?: Array<string>;
	switchPoint: {
		posX: number;
		posY: number;
	};
}

export default class MuseumsLayer extends Component<IMuseumLayerProps> {
	constructor(props, context) {
		super(props, context);
	}

	isCategoryHidden(category, hiddenCategories) {
		return hiddenCategories && hiddenCategories.includes(category);
	}

	focusElement(elementId) {
		const { onMuseumMouseEnter } = this.props;

		if (onMuseumMouseEnter) {
			onMuseumMouseEnter(elementId);
		}
	}

	unfocusElement(elementId) {
		const { onMuseumMouseLeave } = this.props;

		if (onMuseumMouseLeave) {
			onMuseumMouseLeave(elementId);
		}
	}

	render() {
		const { museums = [], projection, switchPoint, hoveredElement, hiddenCategories } = this.props;

		const locations = museums.filter((museum) => {
			if (!(museum.category === MuseumCategoryTypes.PRIMARY || museum.category === MuseumCategoryTypes.SECONDARY)) {
				return false;
			}

			if (this.isCategoryHidden(museum.category, hiddenCategories)) {
				return false;
			}

			if (museum.location === '') {
				return false;
			}

			return true;
		});

		const exhibitions = museums
			.filter((exhibition) => {
				if (exhibition.category !== MuseumCategoryTypes.EXHIBITION) {
					return false;
				}

				if (this.isCategoryHidden(exhibition.category, hiddenCategories)) {
					return false;
				}

				if (exhibition.location === '') {
					return false;
				}

				return true;
			})
			.sort((exhibitionA, exhibitionB) => exhibitionA.geolocation[0] - exhibitionB.geolocation[0]);

		return (
			<MapContext.Consumer>
				{({ activeItem }) => {
					return (
						<Fragment>
							{locations.map((museum, index) => {
								const { x, y } = museum.geolocation;

								const { posX, posY } = projection.geoToPixel(y, x);

								const disabled = hoveredElement && hoveredElement !== museum.id;
								const museumsDisabled = activeItem && activeItem !== MapFocusTypes.MUSEUMS;

								return (
									<Museum
										key={museum.id}
										id={museum.id}
										x={posX}
										y={posY}
										orientationSwitchPoint={switchPoint}
										link={museum.link}
										label={museum.city || museum.title}
										onMouseEnter={this.focusElement.bind(this)}
										onMouseLeave={this.unfocusElement.bind(this)}
										disabled={disabled || museumsDisabled}
									/>
								);
							})}

							{exhibitions.map((exhibitions, index) => {
								const { x, y } = exhibitions.geolocation;

								const { posX, posY } = projection.geoToPixel(y, x);

								const disabled = hoveredElement && hoveredElement !== exhibitions.id;
								const exhibitsDisabled = activeItem && activeItem !== MapFocusTypes.EXHIBITS;

								return (
									<Exhibition
										key={exhibitions.id}
										id={exhibitions.id}
										x={posX}
										y={posY}
										link={exhibitions.link}
										label={exhibitions.city || exhibitions.title}
										status={exhibitions.status}
										onMouseEnter={this.focusElement.bind(this)}
										onMouseLeave={this.unfocusElement.bind(this)}
										disabled={disabled || exhibitsDisabled}
									/>
								);
							})}
						</Fragment>
					);
				}}
			</MapContext.Consumer>
		);
	}
}
