const defaultSettings = {
	mapWidth: 1200,
	mapHeight: 1193,
	mapLngLeft: -180,
	mapLngRight: 180,
	mapLatBottom: -85,
};

export const MapFocusTypes = {
	MUSEUMS: 'museums',
	EXHIBITS: 'exhibits',
	CLUSTERS: 'clusters',
};

export const MuseumCategoryTypes = {
	PRIMARY: 'Primary',
	SECONDARY: 'Secondary',
	EXHIBITION: 'Exhibition',
};

export function debounce(fn, delay) {
	let timer = null;

	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => fn(...args));
	};
}

export class MercatorProjection {
	settings: {
		mapWidth: number;
		mapHeight: number;
		mapLngLeft: number;
		mapLngRight: number;
		mapLatBottom: number;
	};

	mapLngDelta: number;

	mapLatBottomDegree: number;

	constructor(settings) {
		this.settings = {
			...defaultSettings,
			...settings,
		};

		this.mapLngDelta = this.settings.mapLngRight - this.settings.mapLngLeft;
		this.mapLatBottomDegree = (this.settings.mapLatBottom * Math.PI) / 180;
	}

	geoToPixel(lat, lng) {
		const { mapWidth, mapHeight, mapLngLeft } = this.settings;
		const { mapLngDelta, mapLatBottomDegree } = this;
		const posX = (lng - mapLngLeft) * (mapWidth / mapLngDelta);
		const latDegree = (lat * Math.PI) / 180;
		const worldMapWidth = ((mapWidth / mapLngDelta) * 360) / (2 * Math.PI);
		const mapOffsetY =
			(worldMapWidth / 2) * Math.log((1 + Math.sin(mapLatBottomDegree)) / (1 - Math.sin(mapLatBottomDegree)));
		const posY =
			mapHeight - ((worldMapWidth / 2) * Math.log((1 + Math.sin(latDegree)) / (1 - Math.sin(latDegree))) - mapOffsetY);

		return {
			posX: Number(posX.toFixed(2)),
			posY: Number(posY.toFixed(2)),
		};
	}
}
