import { MuseumCategoryTypes } from '@/components/features/map/Map.utils';
import { IClusterResource } from '@/interfaces/IClusterResource';
import { Coords } from 'google-map-react';

export function filterActiveMuseums(museumsCollection) {
	return museumsCollection?.filter((museum) => {
		const museumCategories = [MuseumCategoryTypes.PRIMARY, MuseumCategoryTypes.SECONDARY];

		if (museum.location === '') {
			return false;
		}

		return museumCategories.includes(museum.category);
	});
}

export function filterExhibitionMuseums(museumsCollection) {
	return museumsCollection?.filter((museum) => {
		const museumCategories = [MuseumCategoryTypes.EXHIBITION];

		return museumCategories.includes(museum.category);
	});
}

export function filterExhibitionMuseumsWithLocation(museumsCollection) {
	return museumsCollection?.filter((museum) => {
		const museumCategories = [MuseumCategoryTypes.EXHIBITION];

		return museumCategories.includes(museum.category) && museum.location !== '';
	});
}

export function getBreakupLocation(clusters: Array<IClusterResource>) {
	const breakupLocation: Coords = {
		lat: 0,
		lng: 0,
	};

	if (clusters.length > 0) {
		// Locates the cluster with most breakups
		const cluster = clusters.reduce((max: IClusterResource, curr) => {
			return curr.objectCount > max.objectCount ? curr : max;
		}, clusters[0]);

		breakupLocation.lat = parseInt(cluster.latitude, 10);
		breakupLocation.lng = parseInt(cluster.longitude, 10);
	}

	return breakupLocation;
}
