import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import { markerStyle, createElementDescriptor, animationConfig, initialAnimationStyle } from './utils';
import styles from './Museum.module.scss';
import { motion } from 'framer-motion';

export default class Museum extends Component {
	render() {
		const { x, y, scale, label, link, disabled, onMouseEnter, onMouseLeave, orientationSwitchPoint } = this.props;

		const museumClassNames = classNames(styles.museum, {
			[styles.museumDisabled]: disabled,
			[styles.museumTopLeft]: orientationSwitchPoint.posX >= x,
			[styles.museumTopRight]: orientationSwitchPoint.posX < x,
		});

		const element = createElementDescriptor(link);

		return (
			<element.type className={museumClassNames} style={markerStyle(x, y, 1)} {...element.props}>
				<motion.div
					initial={initialAnimationStyle}
					animate={animationConfig}
					transition={{
						duration: 0.5,
					}}
					className={styles.clusterPositioner}
				>
					<div className={styles.museumPositioner}>
						<div className={styles.museumWrapper}>
							<div
								className={styles.museumContent}
								onMouseEnter={() => onMouseEnter(this.props.id)}
								onMouseLeave={() => onMouseLeave(this.props.id)}
							>
								<div className={styles.museumText}>{label}</div>
							</div>
						</div>
					</div>
				</motion.div>
			</element.type>
		);
	}
}
