import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'next-i18next';
import { NextRouter, withRouter } from 'next/router';
import { Component } from 'react';
import styles from './Filter.module.scss';

interface WithRouterProps {
	router: NextRouter;
}

interface IFilterProps extends WithTranslation, WithRouterProps {
	collection: Array<string>;
	onFilterChange?: (filter: any) => void;
	order?: (collection: Array<any>) => any;
	labels?: {};
}

class Filter extends Component<IFilterProps> {
	constructor(props: IFilterProps) {
		super(props);
	}

	onFilterClick(item) {
		if (!this.props.router.query?.filter !== item) {
			this.props.onFilterChange?.(item);
		}
	}

	getItemClassNames(item) {
		const filters = this.props.order ? this.props.order(this.props.collection) : this.props.collection;
		const { query } = this.props.router;

		return classNames(styles.filterItem, {
			[styles.filterItemActive]: query?.filter ? item === query.filter : item === filters[0],
		});
	}

	render() {
		const { collection, labels, t } = this.props;
		const items = this.props.order ? this.props.order(collection) : collection;

		return (
			<ul className={styles.filter}>
				{items.map((item) => {
					return (
						<li className={this.getItemClassNames(item)} key={item} onClick={this.onFilterClick.bind(this, item)}>
							{labels ? t(labels[item]) : t(item)}
						</li>
					);
				})}
			</ul>
		);
	}
}

export default withTranslation()(withRouter(Filter));
