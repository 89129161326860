export function markerStyle(xPosition, yPosition, scale) {
	return {
		transform: `translate(${xPosition}px, ${yPosition}px) scale(${scale})`,
		WebkitTransform: `translate(${xPosition}px, ${yPosition}px) scale(${scale})`,
	};
}

export function createElementDescriptor(link) {
	const type = link ? 'a' : 'div';
	const href = link || null;

	return {
		type,
		props: {
			href,
		},
	};
}

export const getAnimationDelay = (index, totalSize, maxDelay = 0.5) => {
	// scale delay of each item based on index and total item size
	// item #1 will be delayed by 0.02s, item #2 by 0.03s
	// max delay will be 0.5
	const delay = (index * maxDelay) / totalSize;
	return +delay.toFixed(2);
};

export const initialAnimationStyle = { opacity: 0, scale: 0 };
export const animationConfig = { opacity: [0, 0, 0, 0.5, 0.75, 1], scale: [0.25, 0.5, 0.7, 0.9, 1.2, 1] };
