import classNames from 'classnames';
import { Component } from 'react';

import { motion } from 'framer-motion';
import styles from './Cluster.module.scss';

function markerStyle(xPosition, yPosition, scale) {
	return {
		transform: `translate(${xPosition}px, ${yPosition}px scale(${scale}))`,
		WebkitTransform: `translate(${xPosition}px, ${yPosition}px) scale(${scale})`,
	};
}

interface IClusterProps {
	id: string;
	key: string;
	x: number;
	y: number;
	animationDelay: number;
	count: string;
	clickHandler: () => void;
	onMouseEnter: (id: string) => void;
	onMouseLeave: (id: string) => void;
	disabled: boolean;
}

const initialAnimationStyle = { opacity: 0, scale: 0 };
const animationConfig = { opacity: [0, 0, 0, 0.5, 0.75, 1], scale: [0.25, 0.5, 0.7, 0.9, 1.2, 1] };

export default class Cluster extends Component<IClusterProps> {
	render() {
		const { x, y, id, count, animationDelay, clickHandler, onMouseEnter, onMouseLeave, disabled } = this.props;

		const clusterClassNames = classNames(styles.cluster, {
			[styles.clusterDisabled]: disabled,
		});

		return (
			<div
				className={clusterClassNames}
				style={markerStyle(x, y, 1)}
				onClick={clickHandler}
				onMouseEnter={() => onMouseEnter(id)}
				onMouseLeave={() => onMouseLeave(id)}
			>
				<div className={styles.clusterContent}>
					<motion.div
						initial={initialAnimationStyle}
						animate={animationConfig}
						transition={{
							delay: animationDelay,
							duration: 0.5,
						}}
						className={styles.clusterPositioner}
					>
						<div className={styles.clusterIcon}>
							<svg className={styles.clusterSvg} viewBox="0 0 11 10">
								<path
									d="M5.5 1.507L8.183 0l1.835.793L11 3.25 9.75 5.88l-.368.778-2.103 1.81L5.486 10l-1.66-1.373L2.58 7.52l5.603-1.224L.948 5.49 0 3.25.85.897 2.75 0"
									fillRule="evenodd"
								/>
							</svg>
						</div>
						<div className={styles.clusterText}>{count}</div>
					</motion.div>
				</div>
			</div>
		);
	}
}
