import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import styles from './MapStatsItem.module.scss';
import Loader from '@/components/features/map/Loader';

export default class MapStatsItem extends Component {
	onMapStatsItemMouseEnter() {
		const { onMouseEnter, id } = this.props;

		if (onMouseEnter) {
			onMouseEnter(id);
		}
	}

	render() {
		const { label, count, loaded, icon, onClick, onMouseLeave, disabled } = this.props;

		const mapStatsItemClassNames = classNames(styles.mapStatsItem, {
			[styles.mapStatsItemLink]: onClick,
			[styles.mapStatsItemDisabled]: disabled,
		});

		return (
			<div
				className={mapStatsItemClassNames}
				onClick={onClick}
				onMouseEnter={this.onMapStatsItemMouseEnter.bind(this)}
				onMouseLeave={onMouseLeave}
			>
				<div className={styles.mapStatsItemTitle}>{label}</div>
				<div className={styles.mapStatsItemBody}>
					<div className={styles.mapStatsItemRow}>
						<div className={styles.mapStatsItemIcon}>
							<img src={icon} />
						</div>
						<div className={styles.mapStatsItemValue}>
							{loaded ? <div className={styles.mapStatsItemAnimator}>{count}</div> : <Loader />}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
