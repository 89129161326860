import classNames from 'classnames';
import styles from './Loader.module.scss';

const Loader = (props) => {
	const { done } = props;

	const loaderSvgClassNames = classNames(styles.loaderSvg, {
		[styles.loaderSvgLoading]: !done,
		[styles.loaderSvgDone]: done,
	});

	return (
		<div className={styles.loader}>
			<svg className={loaderSvgClassNames} viewBox="0 0 11 10">
				<path
					d="M5.5 1.507L8.183 0l1.835.793L11 3.25 9.75 5.88l-.368.778-2.103 1.81L5.486 10l-1.66-1.373L2.58 7.52l5.603-1.224L.948 5.49 0 3.25.85.897 2.75 0"
					fillRule="evenodd"
				/>
			</svg>
		</div>
	);
};

export default Loader;
