import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'next-i18next';
import Link from 'next/link';
import { Component } from 'react';
import styles from './AddPost.module.scss';

interface IAddPostProps extends WithTranslation {
	to: string;
	/**
	 * This props are literal strings because translations keys are also literal strings
	 * todo: refactor this when we refactor translation keys to something meaningful
	 */
	text: 'contribute-objects' | 'pinshare-a-story' | 'host-an-exhibition';
	icon: string;
	className?: string;
}

class AddPost extends Component<IAddPostProps> {
	render() {
		const { text, icon, className, to, t } = this.props;

		const addPostClassName = classNames(styles.addPost, className);

		return (
			<Link href={{ query: { open: to } }} shallow={true} className={addPostClassName}>
				<img className={styles.addPostIcon} src={icon} />
				<div className={styles.addPostText}>{t(text)}</div>
			</Link>
		);
	}
}

export default withTranslation()(AddPost);
